import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import { Layout, Listing, Wrapper, Title, Feed, Header, PostContent, PostImages, PostThumbnails } from '../components'
import Img from 'gatsby-image'

class Archive extends Component {
  render() {
    const {
      data: { homepage, posts },
    } = this.props
    // console.log(posts)
    return (
      <Layout>
        <Header>
          <div className='left'>
            <button onClick={() => {window.history.back()}} className="back">Back</button>
          </div>
          <div className='right'>
          </div>
        </Header>
        <Feed>
          <div className="archive">
          {posts.nodes.map((post, j) => (
            // <Link to={post.uid} key={j}><p>hi</p></Link>
            <PostThumbnails
              uid={post.uid}
              image_set={post.data.image_set}
              key={j}
            />
          ))}
          </div>
        </Feed>
      </Layout>
    )
  }
}

export default Archive

Archive.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.shape({
      data: PropTypes.shape({
        title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        content: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
      }),
    }),
    posts: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const pageQuery = graphql`
  query ArchiveQuery {
    homepage: prismicHomepage {
      data {
        title {
          text
        }
      }
    }
    posts: allPrismicPost(sort: { fields: [data___date], order: DESC }) {
      nodes {
        id
        href
        uid
        slugs
        data {
          image_set {
            layout
            image_1 {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            image_2 {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            } 
          }
        }
      }
    }
  }
`
